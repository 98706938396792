import { Injectable } from "@angular/core";
import { securityMock } from "src/app/mocks/securityMocks";
import { of, Observable } from "rxjs";
import { AppService } from "src/app/app.service";
//added on 6/30/2022
import {WorkQueueModel} from 'src/app/models/work-queue-model';
@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(private appservice: AppService) {}

  saveSecurity(payload): Observable<any> {
    return this.appservice.saveSecurity(payload);
  }

  // Control Table -start
  getControlTable():Observable<any>{
    return this.appservice.getControlTable();
  }
  saveControlTable(payload): Observable<any> {
    return this.appservice.saveControlTable(payload);
  }
  // Control Table -end

  // source Setting -start
  getSourceSetting():Observable<any>{
    return this.appservice.getSourceSetting();
  }
  saveSourceSetting(payload): Observable<any> {
    return this.appservice.saveSourceSetting(payload);
  }
  // source Setting -end

  // priority admin -start
  getCourtStatusField():Observable<any>{
    return this.appservice.getCourtStatusField();
  }
  getCourtStatusFieldValue(payload) {
    return this.appservice.getCourtStatusFieldValue(payload);
  }
  getPriorityAdminSearchResult(payload: any[]) {
    return this.appservice.getPriorityAdminSearchResult(payload);
  }
  getCourtPriorities() {
    return this.appservice.getCourtPriorities();
  }
  updatePriorities(payload: any[]) {
    return this.appservice.updatePriorities(payload);
  }
  // priority admin -end

  
  getSelectTables() {
    return this.appservice.getSelectTables();
  }
  getSelectFields(payload: any) {
    return this.appservice.getSelectFields(payload);
  }
  getWhereFields() {
    return this.appservice.getWhereFields();
  }
  getWhereValues(payload: any) {
    return this.appservice.getWhereValues(payload);
  }
  runQuery(data: any,pageNumber:Number=1,rowsPerPage:Number=100,rowsCount:Boolean=false) {
    return this.appservice.runQuery(data,pageNumber,rowsPerPage,rowsCount);
  }
  GetQueries() {
    return this.appservice.GetQueries();
  }
  SaveSharedQuery(data: any) {
    return this.appservice.SaveSharedQuery(data);
  }
  SaveMyQuery(data: any) {
    return this.appservice.SaveMyQuery(data);
  }
  GetQueryDetails(data: any) {
    return this.appservice.GetQueryDetails(data);
  }  

  GetAnalyticsData() {
    return this.appservice.GetAnalyticsData();
  }
  GetAnalyticsCourtStatusData() {
    return this.appservice.GetAnalyticsCourtStatusData();
  }

  GetWorkQueueData(data: any) {
    return this.appservice.GetWorkQueueData(data);
  }
//added on 6/30/2022
  GetWorkQueueDataPaginated(pageNumber:number,rowsPerPage:number) {

    return this.appservice.GetWorkQueueDataPaginated(pageNumber,rowsPerPage);
  }
  GetWorkQueueDataFiltered(data: WorkQueueModel) {
    return this.appservice.GetWorkQueueDataFiltered(data);
  }
  GetWorkQueueDataTotal(data: Number) {
    return this.appservice.GetWorkQueueDataTotal(data);
  }
  CheckIsLocked(payload: { id: any; userid: any; }) {
    return this.appservice.CheckIsLocked(payload);
  }
  UncheckIsLocked(updatedData: { id: any; userid: any; }) {
    return this.appservice.UncheckIsLocked(updatedData);
  }
  GetWorkQueueDataAll(data: any) {
    return this.appservice.GetWorkQueueDataAll(data);
  }
  SaveWorkQueueDataAll(data: { userName: any; workQueueDataList: any[]; }) {
    return this.appservice.SaveWorkQueueDataAll(data);
  }
  GetTempWorkQueueDataAll(data: any) {
    return this.appservice.GetTempWorkQueueDataAll(data);
  }
  SaveTempWorkQueueDataAll(data: { userName: any; workQueueDataList: any[]; }) {
    return this.appservice.SaveTempWorkQueueDataAll(data);
  }

  GetStateData()
  {
    return this.appservice.GetStateData();
  }
    
    

}
