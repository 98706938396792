export enum notificationMessage {
  SUCCESS = "Success",
  ERROR = "Error",
  SECURITY_SUCCESS = "Security data was updated Successfully!",
  WHERE_ERROR = "Please fill the query conditions!",
  PA_NORESULT = "Query has no Matched data found!",
  UPDATE_PRIORITY_ERROR = "Please select update priority value!",
  QUERY_NAME = "Please enter a valid query name!",
  UNLOCK_SOURCE_ERROR = "Please select source(s) to unlock!",
  PAGE_NOT_FOUND = "Page Not Found!",
  QUERY_ITEMS_ERROR = "Incomplete Query!\n Make sure a table, some fields and where clause are completed.",
  QUERY_NO_DATA_FOUND = "No data found for the query!"

}